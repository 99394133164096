<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>报表开发</span>
        </div>
      </div>
      <div class="select-content">
        <el-form class="form" ref="searchForm" :model="searchParams" label-width="160px" inline>
          <el-form-item label="UID编号" prop="uid">
            <el-input
              clearable
              v-model.trim="searchParams.uid"
              @keyup.enter.native="handleQuery"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="queryTime">
            <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              v-model="searchParams.queryTime"
              type="daterange"
              range-separator="至"
            ></el-date-picker>
          </el-form-item>
          <span v-show="selectExpended">
          </span>
          <el-form-item>
            <select-button
              show-status
              @select="handleQuery"
              @reset="handleReset"
            >
            </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>报表列表</span>
          </div>
          <div>
            <el-button type="primary" @click="handleAdd()">新增</el-button>
            <el-button type="danger" @click="handleBatchDelete()">删除</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table ref="tableRef" v-loading="tableLoading" :data="tableData" 
                    :header-cell-style="handleHeaderCellStyle" border
                    stripe @selection-change="handleSelectionChange">
            <el-table-column fixed="left" type="selection" width="50"/>
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column
              prop="uid"
              label="UID编号"
              align="center"
              min-width="130"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="categoryName"
              label="报表类型"
              min-width="80"
              align="center"
              :show-overflow-tooltip="true"
              :formatter="categoryFormat"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="报表名称"
              min-width="100"
              :show-overflow-tooltip="true"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sqlText"
              label="查询SQL"
              min-width="170"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="创建日期"
              min-width="100"
              align="center"
              prop="createDate"
            ></el-table-column>

            <el-table-column
              label="操作"
              align="center"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"
                  >详情</el-button
                >
                <el-button type="text" @click="handleUpdate(scope.row)"
                  >修改</el-button
                >
                <el-button type="text" @click="handleDelete(scope.row)">
                   删除
                </el-button>
                <el-dropdown @command="handleCommandLine">
                  <span class="el-dropdown-link"
                    >更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeHandleCommandLine(scope, 'reportAddress')"
                      >报表地址</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommandLine(scope, 'reportDisplay')"
                      >报表展现</el-dropdown-item
                    > 
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[15, 30, 50, 100]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
      <!-- 报表编辑 -->
      <!-- <el-dialog append-to-body :title="editTitle" :visible.sync="dialogFormVisible" width="1100px" :show-close="true"> -->
      <!-- </el-dialog> -->
      <easy-report-edit
        :types="types"
        ref="easyReportEdit"
        @dialogBoolHide="dialogBoolHide"
        :dialogFormVisible="dialogFormVisible"
        :mainDialogWidth="mainDialogWidth"
        :rowData="rowData"
      ></easy-report-edit>

      <!-- 报表访问链接 -->
      <el-dialog
        v-if="dialogVisible"
        title="报表访问链接"
        :visible.sync="dialogVisible"
        :show-close="false"
        width="500px"
        class="link-dialog"
      >
        <span>{{ httpAddress }}</span>
        <span slot="footer" class="bottom-btn">
          <el-button @click="dialogVisible=false">关闭</el-button>
        </span>
      </el-dialog>

      <!-- 批量删除 -->
      <el-dialog append-to-body width="20%" :visible.sync="batchDeleteVisible">
        <div class="dialog-delete">
          <span>是否删除选中报表信息?</span>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button type="default" @click="cancelDelete">取消</el-button>
          <el-button type="primary" @click="confirmDelete">确认</el-button>
        </div>
      </el-dialog>
    </div>
  <!-- <router-view v-else></router-view> -->
</template>

<script>
import {tableStyle} from '@/util/mixins';
import {searchOrderPage, deleteReport} from '@/service/easyReport.js';
import easyReportEdit from './edit';

export default {
  name: 'easyReportList',
  components: { easyReportEdit },
  mixins: [tableStyle],
  data() {
    return {
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      selections: [], //选择行
      dialogFormVisible: false, //弹窗显示隐藏
      mainDialogWidth: '1100px',
      types: "look",
      dialogVisible: false,
      httpAddress: '',
      //报表类型
      categoryIdOptions: [
        {
          "code": "1",
          "attribute": "普通报表"
        },
        {
          "code": "2",
          "attribute": "折线图表"
        },
        {
          "code": "3",
          "attribute": "柱状图表"
        },
        {
          "code": "4",
          "attribute": "饼状图表"
        },
      ], 
      rowData: {},
      // 筛选条件
      searchParams: {
        uid: '',
        queryTime: '',
        page: 1,
        size: 15
      },
      //分页数据
      page: {
        size: 20,
        current: 1,
        total: 0,
      },
      lockObject: {
        orgId: Number(localStorage.getItem('orgId')),
        code: undefined,
        name: ''
      },
      tableLoading: false, // 表格加载loading
      versionVisible: false, // 版本号弹窗
      editTitle: '', // 编辑弹窗标题
      tableData: [],
      batchDeleteVisible: false,
      singleRecordSign: false,
      singleRecordId: '',
      selectExpended: false,
      params: {
        ids: ''
      }
    };
  },
  created() {
    this.handleQuery();
  },
  watch: {
  },
  methods: {
    handleClick(row, look){

    },
    // 是否跨区域
    isCrossRegionFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '是否跨区域':
          if (value === 1) {
            return '是';
          } else if (value === 0) {
            return '否';
          } else {
            return '';
          }
      }
    },
    // 是否预交
    isPrepayFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '是否预交':
          if (value === 1) {
            return '是';
          } else if (value === 0) {
            return '否';
          } else {
            return '';
          }
      }
    },
    // 弹窗控制
    handleViewDetails(row) {
      this.types = "edit";
      this.rowData = row
      this.dialogFormVisible = true
    },
    handleUpdate(row) {
      this.types = "edit";
      this.rowData = row
      this.dialogFormVisible = true
    },
    handleAdd() {
      this.types = "add";
      this.dialogFormVisible = true
    },
    // 删除报表
    async handleDelete(row) {
      this.batchDeleteVisible = true;
      this.singleRecordSign = true;
      this.singleRecordId = row.id;
    },
    //菜单按钮列表内
    handleCommandLine(command) {
      switch (command.command) {
        case "reportAddress":
          this.reportAddress(command.scope.row);
          break;
        case "reportDisplay":
          this.reportDisplay(command.scope.row);
          break;
      }
    },
    beforeHandleCommandLine(scope, command) {
      return {
        scope: scope,
        command: command,
      };
    },

    // 重置表单
    handleReset() {
      this.searchParams.uid = '';
      this.searchParams.queryTime = '';
    },
    // 查询
    async handleQuery(val) {
      this.searchParams.page = 1;
      if (val) {
        this.searchParams.page = val;
      }
      this.page.current = 1;
      this.page.total = 0;

      this.tableLoading = true;
      const { success, data } = await searchOrderPage({
        filter: this.searchParams,
        paging: this.page
      });
      this.tableLoading = false;
      if (success) {
        this.page = data;
        this.tableData = data.records;
      }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.searchParams.page = value;
      this.cancelDeleteBatch();
      this.handleQuery();
    },
    //关闭报表编辑
    dialogBoolHide(val) {
      if(val == 'onLoad') {
        this.handleQuery();
      }
      this.$refs.easyReportEdit.resetForm()
      this.dialogFormVisible = false;
    },
    /* 表格数据选中 */
    handleSelectionChange(value) {
      this.selections = value;
      this.params.ids = value.map((item) => item.id);
    },
    /* 取消选中 */
    cancelDeleteBatch() {
      this.selections = [];
      this.batchDeleteVisible = false;
      this.$refs.invoiceCenterTable.clearSelection();
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    //导出excel
    async handleBatchExport() {
      if (this.selections.length == 0) {
        this.toast('请勾选需要导出的报表信息', 'warning');
        return;
      }
      let items = this.selections;
      this.exportLoading = true;
      let ids = items.map((item) => item.id);
      // this.downloadFile(rsp);
    },
    /* 批量删除弹窗 */
    handleBatchDelete() {
      if (this.selections.length == 0) {
        this.toast('请勾选报表信息！', 'warning');
        return;
      }
      this.batchDeleteVisible = true;
    },
    /* 批量删除选中票据 */
    async confirmDelete() {
      if (this.singleRecordSign) {
        // 单条删除
        let ids = [this.singleRecordId];
        const { success, message } = await deleteReport(ids);
        if (success) {
          this.toast('删除成功', 'success', () => this.handleQuery());
          this.batchDeleteVisible = false;
          this.cancelDelete();
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      } else {
        // 批量删除
        let ids = this.selections.map((i) => i.id);
        const { success, message } = await deleteReport(ids);
        if (success) {
          this.toast('删除成功！', 'success', () => {
            this.batchDeleteVisible = false;
            this.handleQuery();
            this.cancelDelete();
          });
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      }
      // 重置单条删除标记
      this.singleRecordSign = false;
    },
    //报表类型
    categoryFormat(row) {
      let name = "";
      for (let i = 0; i < this.categoryIdOptions.length; i++) {
        if (this.categoryIdOptions[i].code == row.categoryId) {
          name = this.categoryIdOptions[i].attribute;
          break;
        }
      }
      return name;
    },
    /* 取消选中 */
    cancelDelete() {
      this.selections = [];
      this.batchDeleteVisible = false;
      this.$refs.tableRef.clearSelection();
    },
    // 报表地址
    reportAddress(row) {
      this.dialogVisible = true
      this.httpAddress = window.location.origin + "/easyReport/easyReportDisplay?easyReportUid=" + row.uid
    },
    // 复制地址回调
    onCopy: function () {
      this.dialogVisible = false
      this.$message.success('复制成功')
    },
    onError: function () {
      this.dialogVisible = false
      this.$message.error('复制失败')
    },
    // 报表展示
    reportDisplay(row) {
      this.getReportDisplayByPage(row);
    },
    // 报表展现
    async getReportDisplayByPage(row) {
      // let pageDisplay = {size: 20, current: 1};
      this.loading = true;
      await this.$router.push({
        path: "/easyReport/easyReportDisplay",
        // path: "/easyReport/list",
        query: {
          easyReportUid: row.uid,
        },
      });
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/select.scss";
.tabs_box {
  position: relative;
}
.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          margin-left: 20px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  margin: 24px;
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}
.type_invoice {
  ::v-deep .el-select__tags {
    flex-wrap: nowrap;
    max-width: 88px;
  }
}
.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.kp_title {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
.invoiceImageVisibleHeight {
  ::v-deep .el-dialog {
    height: 100%;
    margin: 0;
    margin-top: 1vh;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 26px;
  }
}
.order_sort {
  display: flex;
  justify-content: space-between;
}


.incvoice_open_goods_list {
  table {
    width: 100%;
  }
}
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
</style>
